import React from 'react';
import PropTypes from 'prop-types';
import styles from './subscribe-section.module.css';

const SubscribeSection = (props) => {
  // Splitting the text into two lines
  const textLines = props.text.split(' ');

  return (
    <div className={` ${styles['container']} ${styles[props.rootClassName]} `}>
      <span className={styles['text']}>
        {textLines.map((line, index) => (
          <React.Fragment key={index}>
            <span>{line}</span>
            {index < textLines.length - 1 && <br style={{lineHeight: '0'}} />} {/* Adding <br> between lines */}
          </React.Fragment>
        ))}
      </span>
    </div>
  );
};

SubscribeSection.defaultProps = {
  rootClassName: '',
  text: 'THANK YOU',
};

SubscribeSection.propTypes = {
  rootClassName: PropTypes.string,
  text: PropTypes.string,
};

export default SubscribeSection;
