import React from 'react'
import PropTypes from 'prop-types'
import styles from './hero-section.module.css'
import heroimg from '../images/hero6.png'

const HeroSection = (props) => {
  return (
    <div className={` ${styles['container']} ${styles[props.rootClassName]} `}>
      <img
        src={props.imageSrc}
        alt={props.imageAlt}
        className={styles['image']}
      />
    </div>
  )
}

HeroSection.defaultProps = {
  imageSrc: heroimg,
  imageAlt: 'Hero Image',
  rootClassName: '',
}

HeroSection.propTypes = {
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default HeroSection
