import React from 'react'
import PropTypes from 'prop-types'
import styles from './nav-bar.module.css'
import saarlogo from '../images/saar-bgremove.png'
import ytlogo from '../images/social-icons/icons8-youtube-500.png'
import iglogo from '../images/social-icons/icons8-instagram-500.png'
import fblogo from '../images/social-icons/icons8-facebook-500.png'
import xlogo from '../images/social-icons/icons8-twitterx-500.png'

const NavBar = (props) => {
  return (
    <header
      data-role="Header"
      className={` ${styles['header']} ${styles[props.rootClassName]} `}
    >
      <div className={styles['nav-btns']}>
        <span className={styles['music-btn']}>{props.text1}</span>
        <span className={styles['video-btn']}>{props.text2}</span>
        <span className={styles['store-btn']}>{props.text3}</span>
        <span className={styles['tour-btn']}>{props.text4}</span>
        <span className={styles['subscribe-btn']}>{props.text5}</span>
      </div>
      <img
        alt={props.imageAlt}
        src={props.imageSrc}
        className={styles['saar-logo']}
      />



<div className={styles['social-btns']}>
  <a href={props.youtubeLink} target="_blank" rel="noopener noreferrer">
    <img
      src={props.imageSrc1}
      alt={props.imageAlt1}
      className={styles['youtube']}
    />
  </a>
  <a href={props.instagramLink} target="_blank" rel="noopener noreferrer">
    <img
      src={props.imageSrc2}
      alt={props.imageAlt2}
      className={styles['instagram']}
    />
  </a>
  <a href={props.facebookLink} target="_blank" rel="noopener noreferrer">
    <img
      src={props.imageSrc4}
      alt={props.imageAlt4}
      className={styles['facebook']}
    />
  </a>
  <a href={props.twitterLink} target="_blank" rel="noopener noreferrer">
    <img
      src={props.imageSrc3}
      alt={props.imageAlt3}
      className={styles['twitter']}
    />
  </a>
</div>


    
    </header>
  )
}

NavBar.defaultProps = {
  imageSrc: saarlogo,
  imageAlt: 'saar-branding',
  text1: 'MUSIC',
  text2: 'VIDEOS',
  text3: 'STORE',
  text4: 'TOUR',
  text5: 'SUBSCRIBE',
  rootClassName: '',
  imageSrc1: ytlogo,
  imageAlt1: 'youtube',
  imageSrc2: iglogo,
  imageAlt2: 'instagram',
  imageSrc3: xlogo,
  imageAlt3: 'twitter',
  imageSrc4: fblogo,
  imageAlt4: 'facebook',

  // Links 
  youtubeLink: "https://go.saarofficial.com/sub",
  instagramLink: "https://go.saarofficial.com/ig",
  facebookLink: "https://go.saarofficial.com/fb",
  twitterLink: "https://go.saarofficial.com/x",
}

NavBar.propTypes = {
  imageSrc: PropTypes.string,
  text5: PropTypes.string,
  imageAlt: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
  text4: PropTypes.string,
  rootClassName: PropTypes.string,
  imageSrc1: PropTypes.string,
  imageAlt1: PropTypes.string,
  imageSrc2: PropTypes.string,
  imageAlt2: PropTypes.string,
  imageSrc3: PropTypes.string,
  imageAlt3: PropTypes.string,
  imageSrc4: PropTypes.string,
  imageAlt4: PropTypes.string,
}

export default NavBar