import React from 'react'
import PropTypes from 'prop-types'
// import projectStyles from '.style.module.css'
import styles from './track.module.css'
import tmmt from '../../images/track-files/tmmt.png'

const Track = (props) => {
  return (
    <div className={` ${styles['container']} ${styles[props.rootClassName]} `}>
      <img src={props.musicart} alt="image" className={styles['image']} />
      <span className={styles['text']}>{props.text}</span>
      <button
        type="button"
        className={` ${styles['button']} ${styles['button']} `}
      >
        {props.stream}
      </button>
    </div>
  )
}

Track.defaultProps = {
  musicart: tmmt,
  text: 'TU MERI MAIN HOON TERA',
  stream: 'STREAM/DOWNLOAD',
}

Track.propTypes = {
  musicart: PropTypes.string,
  text: PropTypes.string,
  rootClassName: PropTypes.string,
  stream: PropTypes.string,
}

export default Track
