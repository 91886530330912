import React from 'react';
import PropTypes from 'prop-types';
import styles from './video-section.module.css';

const VideoSection = (props) => {
  return (
    <div className={` ${styles['container']} ${styles[props.rootClassName]} `}>
      <span className={styles['text']}>{props.text}</span>
      {/* Embedding YouTube video */}
      <div className={styles['video-container']}>
      <iframe
        src="https://www.youtube.com/embed/SKvh4WrelrQ"
        className={styles['iframe']}
      ></iframe>
      </div>
    </div>
  );
};

VideoSection.defaultProps = {
  rootClassName: '',
  text: 'VIDEOS',
};

VideoSection.propTypes = {
  rootClassName: PropTypes.string,
  text: PropTypes.string,
};

export default VideoSection;
