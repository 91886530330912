import React from 'react'
import PropTypes from 'prop-types'
import styles from './tour-section.module.css'

const TourSection = (props) => {
  return (
    <div className={` ${styles['container']} ${styles[props.rootClassName]} `}>
      <span className={styles['text']}>{props.text}</span>
    </div>
  )
}

TourSection.defaultProps = {
  rootClassName: '',
  text: 'TOUR',
}

TourSection.propTypes = {
  rootClassName: PropTypes.string,
  text: PropTypes.string,
}

export default TourSection