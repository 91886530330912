import React from 'react'
import PropTypes from 'prop-types'
import Track from './sub-components/track'
import styles from './music-section.module.css'

const MusicSection = (props) => {
  return (
    <div className={` ${styles['container']} ${styles[props.rootClassName]} `}>
      <span className={styles['text']}>{props.text}</span>
      <Track rootClassName="root-class-name"></Track>
      <Track rootClassName="root-class-name2"></Track>
      <Track rootClassName="root-class-name1"></Track>
    </div>
  )
}

MusicSection.defaultProps = {
  rootClassName: '',
  text: 'MUSIC',

}

MusicSection.propTypes = {
  rootClassName: PropTypes.string,
  text: PropTypes.string,
}



export default MusicSection