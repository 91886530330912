import './App.css';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navbar from './components/nav-bar'
import Hero from './components/hero-section'
import Music from './components/music-section'
import Videos from './components/video-section'
import Tour from './components/tour-section'
import Subscribe from './components/subscribe-section'

function App() {
  return (
    <div>
      <Navbar />
      <Hero />
      <Music />
      <Videos />
      <Tour />
      <Subscribe />

    </div>
  );
}

export default App;